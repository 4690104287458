import React from 'react'
import one from './lobby.svg'
import two from './live.svg'
import six from './SCROLL-DOWN.gif'

import five from './5.svg'



export default function Footer() {
    return (

        <div style={{padding:"0rem 2rem",marginTop:'-5rem'}} id="navba1" href="#about-us-section"
        class="scroll-button smoothscroll paddingLobby 

        
        dn-M" >
        
        <div class="row   fotterPadding" >
        
        <div class="col text-center yellow">
        
        <a  href="https://psasmeets2021.virtuallive.in/lobby.html" >
        
            <img src={one} class="imgLogo " />
            
             
            <p className="menuname">Lobby</p>
        </a>
        </div>


        <div class="col text-center ">
        
        <a id="navIdKnow" onclick="EndTime()"  href="https://psasmeets2021.virtuallive.in/auditorium.html" class="yellow disabled">
          <img src={two} class="imgLogo disabled" /> 
       
   
     
      <p  className="menuname"> Auditorium</p>
      </a>
      </div>

     
        
   
       
        
        <div class="col text-center mrg1 ">
        
          <a id="navIdKnow" onclick="EndTime()"  href="#" class="yellow disabled">
            <img src={six} class="imglo disabled" /> 
         
     
       
        
        </a>
        </div>





   

  
        
          </div>
        
        
        
        
        
        
        
        
        </div> 
    )
}
